<table class="p-4">
  <tbody>
    <tr class="fila" *ngFor="let plan of items; let i = index" id="row-{{ i }}">
      <td [attr.data-column]="column" *ngFor="let column of columns; let o = index">
        <span *ngIf="validators[o] === 'title'" class="cursor-pointer" (click)="showMore(i)">
          {{ values[i][o] }}
          <mat-icon *ngIf="!showed && showed !== i" id="moreIcon" class="icon-size-5 cursor-pointer"
            [svgIcon]="'heroicons_solid:chevron-down'"></mat-icon>
          <mat-icon *ngIf="showed === i" id="moreIcon" class="icon-size-5 cursor-pointer"
            [svgIcon]="'heroicons_solid:chevron-up'"></mat-icon>
        </span>
        <span *ngIf="validators[o] === 'price'">
          {{ values[i][o] | currency }}
        </span>
        <span *ngIf="validators[o] === 'data'">
          {{ values[i][o] }}
        </span>
        <span *ngIf="validators[o] === 'date'">
          {{ values[i][o] | date: 'dd/MM/yyyy' }}
        </span>
        <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
          *ngIf="validators[o] === 'info'"
          [ngClass]="{'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': values[i][o] === 'Totalmente firmado',
                                    'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50': values[i][o] === 'Este documento no ha sido firmado',
									'bg-yellow-200 text-yellow-800 dark:bg-yellow-600 dark:text-yellow-50': values[i][o] === 'Parcialmente firmado',
									'bg-orange-200 text-orange-800 dark:bg-orange-600 dark:text-orange-50': values[i][o] === 'Documento rechazado'}">
          <span class="leading-relaxed">
            {{ values[i][o] }}
          </span>
        </span>
        <span *ngIf="validators[o] === 'statussign'">
          <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
            [ngClass]="{'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': values[i][o].Status === 'Firmado',
                'bg-amber-200 text-amber-800 dark:bg-amber-600 dark:text-amber-50': values[i][o].Status === 'Pendiente',
                'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': values[i][o].Status === 'Rechazado'}"
            *ngIf="values[i][o].Action === 'Signed' || !values[i][o].Action">
            <span class="leading-relaxed">{{ values[i][o].Status }}</span>
          </span>
          <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
            *ngIf="values[i][o].Action === 'Following'">
            <span class="leading-relaxed">Colaboración</span>
          </span>
          <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
            *ngIf="values[i][o].Action === 'Notification'">
            <span class="leading-relaxed">Notificado</span>
          </span>
        </span>
        <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
          *ngIf="validators[o] === 'usertype'" [ngClass]="{
            'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50':
              values[i][o] === 'user',
            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50':
              values[i][o] === 'admin',
            'bg-blue-200 text-blue-800 dark:bg-blue-600 dark:text-blue-50':
              values[i][o] === 'business'
          }">
          <span class="leading-relaxed">
            {{ values[i][o] }}
          </span>
        </span>
        <span *ngIf="validators[o] === 'status'">
          <span class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
            [ngClass]="{
              'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50':
                values[i][o] === false,
              'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50':
                values[i][o] === true
            }">
            <span class="leading-relaxed">{{
              values[i][o] === true ? "Activo" : ""
              }}</span>
            <span class="leading-relaxed">{{
              values[i][o] === false ? "Inactivo" : ""
              }}</span>
          </span>
        </span>
        <span *ngIf="validators[o] === 'actions'">
          <span *ngFor="let action of actions; let in = index">
            <a href="" *ngIf="action === 'edit'" (click)="navigate(values[i][o])">
              <mat-icon class="icon-size-5 text-blue-300 cursor-pointer" matPrefix
                [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
            </a>
            <mat-icon class="icon-size-5 text-red-300 cursor-pointer"
              (click)="validateStatus(values[i][o], values[i][o - 1])"
              *ngIf="values[i][o - 1] === true && action === 'delete'" matPrefix
              [svgIcon]="'heroicons_solid:trash'"></mat-icon>
            <mat-icon class="icon-size-5 text-green-300 cursor-pointer" [svgIcon]="'heroicons_solid:check-circle'"
              (click)="validateStatus(values[i][o], values[i][o - 1])"
              *ngIf="values[i][o - 1] === false && action === 'delete'"></mat-icon>

            <mat-icon class="icon-size-5 text-red-300 cursor-pointer" [svgIcon]="'heroicons_solid:trash'"
              (click)="validateStatus(values[i][o], true)" *ngIf="action === 'deletew'"></mat-icon>

            <mat-icon class="icon-size-5 cursor-pointer" [svgIcon]="'heroicons_solid:eye'"
              (click)="seeDetails(values[i][o])" *ngIf="action === 'see'"></mat-icon>

            <mat-icon class="icon-size-5 cursor-pointer" [svgIcon]="'heroicons_solid:variable'"
              (click)="openDialogoSign(dialog, values[i][o])" *ngIf="action === 'sign'"></mat-icon>

            <mat-icon class="icon-size-5 cursor-pointer" [svgIcon]="'heroicons_solid:eye'"
              (click)="seeDocument(values[i][o])" *ngIf="action === 'seed'"></mat-icon>

            <mat-icon class="icon-size-5 cursor-pointer" [svgIcon]="'heroicons_solid:eye'"
              (click)="seeDocumento(values[i][o])" *ngIf="action === 'sees'"></mat-icon>

            <button mat-menu-item *ngIf="action === 'change'" (click)="openDialogo(values[i][o])">
              <mat-icon class="icon-size-5 mx-4" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
            </button>

          </span>
        </span>
      </td>
      <!-- <td data-column="Nombre">
          {{ plan.PlanName }} 
        </td>
        <td data-column="{{ columns[i] }}">
          {{ plan.Price | currency }}
        </td>
        <td data-column="{{ columns[i] }}">
          {{ plan.consumables[0].quantity }}
        </td>
        <td data-column="{{ columns[i] }}">
          {{ plan.consumables[1].quantity }}
        </td>
        <td data-column="{{ columns[i] }}">
          <span
          class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
          [ngClass]="{
            'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50':
              plan.Active === false,
            'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50':
              plan.Active === true
          }"
        >
          <span class="leading-relaxed">{{
            plan.Active === true ? "Activo" : ""
          }}</span>
          <span class="leading-relaxed">{{
            plan.Active === false ? "Inactivo" : ""
          }}</span>
        </span>
        </td>
        <td data-column="{{ columns[i] }}" class="cursor-pointer" >
        <mat-icon class="icon-size-5 text-blue-300 cursor-pointer" matPrefix [routerLink]="['edit-plan', plan.id]" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
        <mat-icon class="icon-size-5 text-red-300 cursor-pointer" (click)="deletePlan(plan.id)" *ngIf="plan.Active === true" matPrefix [svgIcon]="'heroicons_solid:trash'"></mat-icon>
        <mat-icon class="icon-size-5 text-green-300 cursor-pointer" [svgIcon]="'heroicons_solid:check-circle'" (click)="activePlan(plan.id)" *ngIf="plan.Active === false"></mat-icon>
        </td> -->
    </tr>
  </tbody>
</table>


<ng-template #dialog class="dialog">
</ng-template>